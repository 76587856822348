export const graphTypeColor = [
  { type: "疾病", fill: "#8DDBE0", stroke: "#4FE0DE" },
  { type: "临床症状", fill: "#E09CC3", stroke: "#E17CC7" },
  { type: "临床检查", fill: "#E5CBA3", stroke: "#E6BD79" },
  { type: "临床指标", fill: "#A1A4E1", stroke: "#7682E0" },
  { type: "不良反应", fill: "#ACA1E1", stroke: "#8A79E1" },
  { type: "治疗方法", fill: "#BEA5E0", stroke: "#A772E0" },
  { type: "药物治疗", fill: "#CE93E0", stroke: "#CE93E0" },
  { type: "治疗措施", fill: "#DDA4E1", stroke: "#DD7DE1" },
  { type: "预防措施", fill: "#91CAE0", stroke: "#5ABCE0" },
  { type: "护理措施", fill: "#E17CC7", stroke: "#EA89A2" },
  { type: "药物", fill: "#EBAFA9", stroke: "#EA8376" },
  { type: "症状", fill: "#EBBCA9", stroke: "#EA9679" },
  { type: "临床表现", fill: "#EA9679", stroke: "#E6B179" },
  { type: "影响因素", fill: "#9AB4E0", stroke: "#79A2E1" },
  { type: "筛查工具", fill: "#E5D6B2", stroke: "#E5CB86" },
  { type: "临床评估", fill: "#EBBE91", stroke: "#E6DD8A" },
  { type: "非药物治疗措施", fill: "#D6E6B4", stroke: "#C0E587" },
  { type: "临床分期", fill: "#C9E6B3", stroke: "#AAE68F" },
  { type: "临床体征", fill: "#B8E6C3", stroke: "#87E592" },
  { type: "风险因素", fill: "#B0E5CA", stroke: "#85E6B4" },
  { type: "评估方法", fill: "#AEE6D6", stroke: "#7DE6D4" },
  { type: "自我监测内容", fill: "#8CCFE5", stroke: "#29B3E6" },
  { type: "非药物干预措施", fill: "#B8BFE6", stroke: "#627EE6" },
];
