<template>
  <!-- 图谱筛选组建 -->
  <div class="screen-wrapper">
    <div class="screen-condition">
      <div class="condition-all">
        <div
          class="condition"
          @click="handleClickAllType()"
          :class="{
            selected: state.typeListSelected.length === 0 ? true : false,
          }"
        >
          全部实体
        </div>
      </div>
      <el-space wrap class="condition-list" size="large">
        <div
          class="condition"
          :class="{
            selected: isEntitySelected(typeItem),
          }"
          v-for="(typeItem, typeIndex) in state.typeList"
          :key="typeIndex"
          @click="handleClickType(typeItem)"
        >
          {{ typeItem }}
        </div>
      </el-space>
    </div>
    <div class="screen-condition">
      <div class="condition-all">
        <div
          class="condition"
          @click="handleClickAllRelation()"
          :class="{
            selected: state.relationListSelected.length === 0 ? true : false,
          }"
        >
          全部关系
        </div>
      </div>
      <el-space wrap class="condition-list" size="large">
        <div
          class="condition"
          :class="{
            selected: isRelationSelected(relationItem),
          }"
          v-for="(relationItem, relationIndex) in state.relationList"
          :key="relationIndex"
          @click="handleClickRelation(relationItem)"
        >
          {{ relationItem }}
        </div>
      </el-space>
    </div>
  </div>
</template>

<script setup>
import { httpPost } from "@/api/httpService";
import { ref, reactive, onMounted, defineEmits, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const state = reactive({
  entityList: [route.query.entityId], // 当前搜索关键词
  typeList: [], // 分类集合
  relationList: [], // 关系集合
  typeListSelected: [], // 被选择的分类集合
  relationListSelected: [], //被选择的关系集合
});

/**当前实体是否被选中 */
const isEntitySelected = (typeItem) => {
  if (state.typeListSelected.indexOf(typeItem) !== -1) {
    return true;
  } else {
    return false;
  }
};

/**当前关系是否被选中 */
const isRelationSelected = (relationItem) => {
  if (state.relationListSelected.indexOf(relationItem) !== -1) {
    return true;
  } else {
    return false;
  }
};

const emit = defineEmits(["change"]); //传递筛选项
const change = () => {
  let val = {
    typeList: state.typeListSelected,
    relationList: state.relationListSelected,
  };
  emit("change", val);
  // console.log("*****");
};

/**查询实体图谱 */
const searchGraph = async () => {
  let url = "/graph/v1/searchGraph";
  let params = {
    entityList: state.entityList,
    typeList: state.typeList,
    relationList: state.relationList,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    console.log(res.data);
    state.typeList = res.data.typeList;
    state.relationList = res.data.relationList;
    // console.log(state.typeList);
  }
};

/**选中分类 */
const handleClickType = (typeItem) => {
  let index = state.typeListSelected.indexOf(typeItem);
  if (index === -1) {
    state.typeListSelected.push(typeItem);
  } else {
    state.typeListSelected.splice(index, 1);
  }
  // console.log(state.typeListSelected);
  change();
};

/**选中关系 */
const handleClickRelation = (relationItem) => {
  let index = state.relationListSelected.indexOf(relationItem);
  if (index === -1) {
    state.relationListSelected.push(relationItem);
  } else {
    state.relationListSelected.splice(index, 1);
  }
  // console.log(state.relationListSelected);
  change();
};

/**选中全部实体 */
const handleClickAllType = () => {
  state.typeListSelected = [];
  change();
};

/**选中全部关系 */
const handleClickAllRelation = () => {
  state.relationListSelected = [];
  change();
};

onMounted(() => {
  searchGraph();
});
</script>

<style lang="scss" scoped>
.screen-wrapper {
  padding: 20px 50px 0 50px;

  .screen-condition {
    display: flex;
    margin-bottom: 20px;
    .condition-all {
      .condition {
        margin-right: 16px;
      }
    }
    .condition-list {
      display: flex;
      flex: 1;
      :deep .el-space__item {
        padding-bottom: 16px !important;
      }
    }
    .condition {
      height: 26px;
      line-height: 26px;
      color: #777777;
      background-color: #efefef;
      border-radius: 20px;
      padding: 0 15px;
      cursor: pointer;
    }
    .condition:hover {
      color: #4095e5;
    }
    .condition.selected {
      color: #fff;
      background-color: #64a6e1;
    }
  }
}
</style>
