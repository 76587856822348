<template>
  <!-- 图谱页菜单栏 -->
  <el-aside width="18%">
    <div>
      <!-- 添加实体按钮 -->
      <!-- <el-tooltip content="添加实体" placement="right" :enterable="false"> -->
      <el-button
        @click="openDrawer"
        type="primary"
        icon="Plus"
        circle
        disabled
      />
      <!-- </el-tooltip> -->
      <!-- 标签页 -->
      <el-tabs
        v-model="state.activeTab"
        type="border-card"
        class="tab-menu"
        tab-position="left"
        @tab-change="handleTabsChange"
      >
        <!-- 实体标签页 -->
        <el-tab-pane name="entity">
          <template #label>
            <i class="iconfont icon-entity"></i>
          </template>
          <!-- 实体菜单区域 -->
          <div class="entity-menu">
            <!-- 关键词搜索框 -->
            <el-input
              v-model="state.keywords.entity"
              placeholder="输入实体关键词"
              @keyup="filterResultByEntity"
              @clear="searchEntityTypeAgg"
              clearable
            />
            <!-- 主体内容 -->
            <!-- 按名称排序 -->
            <p>
              名称
              <el-icon :class="{ arrow: ifReverseArrow }" @click="orderByName()"
                ><Bottom
              /></el-icon>
            </p>
            <el-scrollbar class="entity-scrollbar">
              <el-menu
                background-color="transparent"
                :collapse-transition="false"
              >
                <el-sub-menu
                  v-for="entityType in state.typeEntitiesList"
                  :key="entityType.type"
                  :index="entityType.type"
                >
                  <template #title>
                    <span>{{ entityType.type }}</span>
                  </template>
                  <el-menu-item
                    v-for="entityItem in getEntityShowList(entityType)"
                    :key="entityItem.entityId"
                    :index="entityType.type + entityItem.entityId"
                    v-showTip
                    @click="handleShowDetail(entityItem.entityId, 'entity')"
                  >
                    <el-tooltip
                      :content="entityItem.name"
                      placement="top"
                      :enterable="false"
                      :show-after="500"
                    >
                      <div class="kn-ellipsis">
                        <span class="ellipse-text">{{ entityItem.name }}</span>
                      </div>
                    </el-tooltip>
                  </el-menu-item>

                  <el-menu-item
                    v-if="entityType.entityList.length > state.defaultNum"
                    class="open-close-btn"
                    :index="'entityCollapseBtn' + entityType.type"
                    @click="switchItemCollapse(entityType)"
                    ><span class="open-close"
                      >{{ entityType.isCollapse ? "更多" : "收起"
                      }}<i
                        class="iconfont icon-doublearrow"
                        :class="{ arrow: !entityType.isCollapse }"
                      ></i></span
                  ></el-menu-item>
                </el-sub-menu>
              </el-menu>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <!-- 关系标签页 -->
        <el-tab-pane name="relation">
          <template #label>
            <i class="iconfont icon-relation"></i>
          </template>
          <!-- 关系菜单区域 -->
          <div class="relation-menu">
            <el-input
              v-model="state.keywords.relation"
              placeholder="输入关系关键词"
              @keyup="filterResultByEntity"
              @clear="searchEntityRelationAgg"
              clearable
            />
            <!-- 主体内容 -->
            <el-scrollbar class="relation-scrollbar">
              <el-menu
                background-color="transparent"
                :collapse-transition="false"
              >
                <el-sub-menu
                  v-for="relationType in state.relationEntitiesList"
                  :key="relationType.name"
                  :index="relationType.name"
                >
                  <template #title>
                    <span>{{ relationType.name }}</span>
                  </template>

                  <el-menu-item
                    v-for="relationItem in getRelationShowList(relationType)"
                    :key="relationItem.relationId"
                    :index="relationType.name + relationItem.relationId"
                    v-showTip
                    @click="
                      handleShowDetail(relationItem.relationId, 'relation')
                    "
                  >
                    <el-tooltip
                      :content="
                        relationItem.sourceName +
                        ' - ' +
                        relationItem.targetName
                      "
                      placement="top"
                      :enterable="false"
                      :show-after="500"
                    >
                      <div class="kn-ellipsis">
                        <span class="ellipse-text">{{
                          relationItem.sourceName +
                          " - " +
                          relationItem.targetName
                        }}</span>
                      </div>
                    </el-tooltip>
                  </el-menu-item>

                  <el-menu-item
                    v-if="relationType.relationList.length > state.defaultNum"
                    class="open-close-btn"
                    :index="'relationCollapseBtn' + relationType.name"
                    @click="switchItemCollapse(relationType)"
                    ><span class="open-close"
                      >{{ relationType.isCollapse ? "更多" : "收起"
                      }}<i
                        class="iconfont icon-doublearrow"
                        :class="{ arrow: !relationType.isCollapse }"
                      ></i></span
                  ></el-menu-item>
                </el-sub-menu>
              </el-menu>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 添加实体抽屉
      <create-entity-drawer ref="addEntityDrawer"></create-entity-drawer> -->
    </div>
  </el-aside>
</template>

<script setup>
import { httpPost } from "@/api/httpService";
import {
  ref,
  reactive,
  getCurrentInstance,
  inject,
  onMounted,
  computed,
} from "vue";
import CreateEntityDrawer from "@/components/graph/CreateEntityDrawer";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const handleOpenDrawer = inject("handleOpenDrawer");
//  前后端变量名需保持一致
const state = reactive({
  activeTab: "entity", //当前被激活的标签页
  defaultNum: 8, // 默认展示数据条数
  keywords: { entity: "", relation: "" }, //实体/关系关键词
  entityId: route.query.entityId, //实体id
  sort: "ASC", //排序方式
  typeEntitiesList: [], // 实体列表
  relationEntitiesList: [], //关系列表
});
const ifReverseArrow = computed(() => {
  return state.sort === "ASC" ? true : false;
}); // 排序箭头样式
/**得到用于展示的实体列表 */
const getEntityShowList = (entityType) => {
  // console.log(entityType);
  if (entityType.isCollapse === false) {
    return entityType.entityList;
  } else {
    return entityType.entityList.slice(0, state.defaultNum);
  }
};
/**得到用于展示的关系列表 */
const getRelationShowList = (relationType) => {
  // console.log(relationType);
  if (relationType.isCollapse === false) {
    return relationType.relationList;
  } else {
    return relationType.relationList.slice(0, state.defaultNum);
  }
};
/**标签页变换*/
const handleTabsChange = (activeTab) => {
  state.activeTab = activeTab;
};
/**查询实体分类聚合 */
const searchEntityTypeAgg = async () => {
  let url = "/graph/v1/searchEntityTypeAgg";
  let params = {
    entityId: state.entityId,
    keywords: state.keywords.entity,
    sort: state.sort,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    // console.log(res);
    state.typeEntitiesList = res.data;

    if (state.defaultNum > 0) {
      // 添加是否折叠属性
      state.typeEntitiesList.forEach((entity) => {
        if (entity.entityList.length < state.defaultNum) {
          entity["isCollapse"] = false;
        } else {
          entity["isCollapse"] = true;
        }
      });
    }
    // console.log(state.typeEntitiesList);
  }
};
/**查询实体关系聚合 */
const searchEntityRelationAgg = async () => {
  let url = "/graph/v1/searchEntityRelationAgg";
  let params = {
    entityId: state.entityId,
    keywords: state.keywords.relation,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    state.relationEntitiesList = res.data;
    // console.log(state.relationEntitiesList);

    if (state.defaultNum > 0) {
      // 添加是否折叠属性
      state.relationEntitiesList.forEach((relation) => {
        if (relation.relationList.length < state.defaultNum) {
          relation["isCollapse"] = false;
        } else {
          relation["isCollapse"] = true;
        }
      });
    }
  }
};

/**按名称排序 */
const orderByName = () => {
  state.sort = state.sort === "ASC" ? "DESC" : "ASC";
  searchEntityTypeAgg();
};

/**打开添加实体抽屉 */
const openDrawer = () => {
  // console.log("tab", proxy);
  // console.log(handleOpenDrawer);
  handleOpenDrawer();
};

/**根据实体关键词筛选结果 */
const filterResultByEntity = () => {
  if (state.activeTab === "entity") {
    searchEntityTypeAgg();
  } else if (state.activeTab === "relation") {
    searchEntityRelationAgg();
  }
};

/**展示详情卡片 */
const handleShowDetail = (id, type) => {
  store.commit("handleDetailShow");
  store.commit("handleIdForDetailCardChange", id);
  store.commit("handletTypeForDetailCardChange", type);
};

/**展开与收起 */
const switchItemCollapse = (currentItem) => {
  currentItem.isCollapse = !currentItem.isCollapse;
};

onMounted(() => {
  // console.log(route.query.entityId);
  searchEntityTypeAgg();
  searchEntityRelationAgg();
});
</script>

<style lang="scss" scoped>
.el-aside {
  position: relative;
  z-index: 999;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);

  // 添加实体按钮
  .el-button {
    position: absolute;
    z-index: 9999;
    width: 30px;
    height: 30px;
    top: 10px;
    left: 10px;
    background-color: #64a6e1;
    border: none;
  }
  :deep .tab-menu {
    height: calc(100vh - 64px);
    background-color: #f5f5f5;
    border: none;
    .el-tabs__header {
      width: 50px;
      margin-right: 0;
      background-color: #e1e1e1;
      box-shadow: -6px -6px 8px -7px #999 inset;
      border: none;
    }

    .el-tabs__content {
      padding: 0;
    }

    .el-tabs__nav {
      margin-top: 50px;
    }

    .el-tabs__item {
      padding: 0;
      text-align: center;

      .iconfont {
        font-size: 24px;
        color: #101010;
      }
    }

    .is-active {
      background-color: #f5f5f5;
      box-shadow: 0 0 7px #999;
    }

    // 实体与关系菜单
    .entity-menu,
    .relation-menu {
      .el-input__wrapper {
        height: 30px;
        margin: 12px 14px 0 14px;
        box-shadow: 0 0 0 0px
          var(--el-input-border-color, var(--el-border-color)) inset;
        border: var(--el-input-border-color, var(--el-border-color)) 1px solid;
        border-radius: 15px;
      }
      // 按名称排序按钮
      p {
        height: 20px;
        padding: 0 14px 0 14px;
        margin: 14px 0 6px 0;
        line-height: 20px;
        text-align: left;
        color: #101010;
        font-size: 14px;

        .el-icon {
          cursor: pointer;
          vertical-align: top;
          font-size: 18px;
        }

        .arrow {
          transform: rotate(180deg);
        }
      }

      .entity-scrollbar {
        height: calc(100vh - 150px);
      }

      .relation-scrollbar {
        height: calc(100vh - 110px);
      }

      // 菜单样式
      .el-menu {
        border: none;
        box-shadow: none;
        text-align: left;

        .el-sub-menu.is-active.is-opened,
        .el-sub-menu__title,
        .el-sub-menu,
        .el-menu-item.is-active,
        .el-menu--inline {
          box-shadow: none;
        }

        .el-sub-menu__title {
          margin: 7px 14px 6px 14px;
          height: 30px;
          padding: 0;
          color: #666;
          font-size: 14px;
        }

        .el-menu-item {
          display: block;
          width: 100%;
          padding: 0 15px;
          margin: 0;
          height: 32px;
          line-height: 32px;
          color: #101010;

          &:hover {
            background-color: #e9e9e9;
          }
        }

        .el-menu-item.is-active {
          background-color: #d0d0d0;
        }

        .el-icon {
          position: absolute;
          right: 0;
        }

        // 展开与关闭按钮
        .open-close-btn {
          text-align: center;
          .iconfont {
            display: inline-block;
            vertical-align: middle;
            margin-left: 4px;
            font-size: 12px;
          }
          .open-close {
            color: #101010;

            .arrow {
              transform: rotate(180deg) translateY(1px);
            }
          }

          &:hover {
            background-color: #e9e9e9 !important;
          }
        }

        .el-menu-item.open-close-btn.is-active {
          background-color: transparent;
        }
      }
    }
  }
}
</style>
