<template>
  <!-- 图谱右侧详情卡片 -->
  <el-aside width="20%" v-if="ifDetailVisible">
    <!-- 关闭详情页按钮 -->
    <div class="top-bar">
      <el-button class="close-detail-btn" @click="handleCloseDetailCard()"
        ><el-icon><Close /></el-icon
      ></el-button>
    </div>
    <el-scrollbar>
      <el-card shadow="always" class="detail-card">
        <!-- 实体信息详情 -->
        <div class="entity-detail-wrapper" v-if="showCard === 'entity'">
          <!-- 图片 -->
          <img src="@/assets/images/detail/detail.png" alt="" />
          <!-- 标题栏 -->
          <el-row class="top-title">
            <el-col :span="2"><i class="iconfont icon-entity"></i></el-col>

            <el-col :span="16">
              <h3
                class="entity-title"
                v-showTip
                v-if="state.ifReloadtitle"
                @click="handleSearchCurrentEntity"
              >
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="state.name"
                  placement="top"
                  :enterable="false"
                >
                  <div class="kn-ellipsis">
                    <span class="ellipse-text">{{ state.name }} </span>
                  </div>
                </el-tooltip>
              </h3>
            </el-col>

            <el-col :span="6">
              <!-- <a>编辑</a> -->
            </el-col>
          </el-row>
          <!-- tag -->
          <div class="tag-belong">
            <el-tag
              :disable-transitions="true"
              v-for="(typeItem, typeIndex) in state.typeList"
              :key="typeIndex"
              >{{ typeItem }}</el-tag
            >
          </div>
          <!-- 描述信息 -->
          <p class="description kn-text-align-justify">
            {{ state.description }}
          </p>
          <!-- 列表信息 -->
          <el-row
            class="detail-list"
            v-for="(attributesItem, attributesIndex) in state.attributesList"
            :key="attributesIndex"
          >
            <div class="detail-name">{{ attributesItem.name }}</div>
            <div class="detail-value">{{ attributesItem.value }}</div>
          </el-row>
        </div>
        <!-- 关系信息详情 -->
        <div
          class="relation-detail-wrapper"
          v-else-if="showCard === 'relation'"
        >
          <!-- 标题栏 -->
          <el-row class="top-title">
            <el-col :span="2"><i class="iconfont icon-relation"></i></el-col>

            <el-col :span="19">
              <h3 class="relation-title" v-if="state.ifReloadtitle">
                <div v-showTip class="relation-entity-name">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="state.sourceName"
                    placement="top"
                    :enterable="false"
                  >
                    <div class="kn-ellipsis">
                      <span class="ellipse-text">{{ state.sourceName }}</span>
                    </div>
                  </el-tooltip>
                </div>

                <i></i>

                <div v-showTip class="relation-entity-name">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="state.targetName"
                    placement="top"
                    :enterable="false"
                  >
                    <div class="kn-ellipsis">
                      <span class="ellipse-text">{{ state.targetName }}</span>
                    </div>
                  </el-tooltip>
                </div>
              </h3></el-col
            >

            <el-col :span="3">
              <!-- <a>编辑</a> -->
            </el-col>
          </el-row>
          <!-- 关系tag -->
          <div class="tag-belong">
            <el-tag :disable-transitions="true">{{ state.type }}</el-tag>
          </div>
          <!-- 描述信息 -->
          <!-- <p class="description kn-text-align-justify">第三任妻子</p> -->
          <!-- 列表信息 -->
          <el-row
            class="detail-list"
            v-for="(attributesItem, attributesIndex) in state.attributesList"
            :key="attributesIndex"
          >
            <div class="detail-name">{{ attributesItem.name }}</div>
            <div class="detail-value">
              {{ attributesItem.value }}
            </div>
          </el-row>
        </div>
      </el-card>
    </el-scrollbar>
  </el-aside>
</template>

<script setup>
import {
  reactive,
  defineProps,
  watch,
  computed,
  onMounted,
  onUpdated,
  inject,
} from "vue";
import { httpPost } from "@/api/httpService";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
let router = useRouter();
let route = useRoute();
const store = useStore();
const reload = inject("reload");
// const props = defineProps({
//   id: {
//     type: String,
//   },
//   type: {
//     type: String,
//   },
// });

const state = reactive({
  name: "", // 名称
  typeList: [], //实体类型集合
  description: "", //简介
  attributesList: [], //属性列表
  type: "", //关系类型
  sourceName: "", //关系来源实体
  targetName: "", //关系目标实体
  ifReloadtitle: "false", //是否重新加载标题
});
const ifDetailVisible = computed(() => store.state.ifDetailVisible); //是否显示详情卡片
const showCard = computed(() => store.state.typeForDetailCard); //展示实体/关系详情卡片
const showId = computed(() => store.state.idForDetailCard); //当前展示的实体/关系的id
/**查询实体详情 */
const searchEntityDetail = async () => {
  state.ifReloadtitle = false;
  let url = "/graph/v1/searchEntityDetail";
  let params = {
    // entityId: props.id
    entityId: showId.value,
  };
  // console.log("card");
  let res = await httpPost(url, params);
  if (res.code == 0) {
    // console.log(res.data);
    state.name = res.data.name;
    state.typeList = res.data.typeList;
    state.description = res.data.description;
    state.attributesList = res.data.attributesList;
    state.ifReloadtitle = true;
  }
};
/**查询关系详情 */
const searchRelationDetail = async () => {
  state.ifReloadtitle = false;
  let url = "/graph/v1/searchRelationDetail";
  let params = {
    relationId: showId.value,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    state.name = res.data.name;
    state.type = res.data.type;
    state.sourceName = res.data.sourceName;
    state.targetName = res.data.targetName;
    state.attributesList = res.data.attributesList;
    state.ifReloadtitle = true;
    // console.log("relation-card", res.data);
  }
};
// 关闭详情卡片
const handleCloseDetailCard = () => {
  store.commit("handleDetailDisappear");
};
// 展示关系/实体详情
const handleShowEntityOrRelation = () => {
  if (showCard.value === "entity") {
    searchEntityDetail();
  } else if (showCard.value === "relation") {
    searchRelationDetail();
  }
};
/**进入（搜索）当前实体 */
const handleSearchCurrentEntity = () => {
  console.log("before", showId.value);
  router.push({
    path: "./graph",
    query: { entityId: showId.value, keywords: state.name },
  });
};
watch(
  () => route,
  () => {
    reload();
  },
  { immediate: false, deep: true }
);
watch(
  () => showId,
  () => {
    handleShowEntityOrRelation();
  },
  { immediate: false, deep: true }
);
onMounted(() => {
  store.commit("handleIdForDetailCardChange", route.query.entityId);
  store.commit("handletTypeForDetailCardChange", "entity");
  handleShowEntityOrRelation();
});

onUpdated(() => {
  handleShowEntityOrRelation();
  // console.log(store.state.idForDetailCard);
  // console.log(store.state.typeForDetailCard);
});
</script>

<style lang="scss" scoped>
.el-aside {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
  .top-bar {
    position: relative;
    height: 20px;
    background-color: #f5f5f5;
    // 关闭详情按钮
    .close-detail-btn {
      position: absolute;
      top: -7px;
      right: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      --el-button-hover-text-color: #666;
      font-size: 16px;
    }
  }

  .el-scrollbar {
    height: calc(100vh - 84px);
    .detail-card {
      min-height: calc(100vh - 84px);
      background-color: #f5f5f5;
      border-radius: 0;
      border: 0;

      :deep(.el-card__body) {
        padding: 0px 15px 0 15px;
      }

      .relation-detail-wrapper,
      .entity-detail-wrapper {
        // 信息标题
        .top-title {
          .el-col {
            text-align: left;
            line-height: 20px;
            margin-top: 10px;

            i {
              font-size: 20px;
            }

            h3 {
              font-size: 16px;
            }

            .entity-title {
              cursor: pointer;
              white-space: nowrap;

              &:hover {
                color: #4095e5;
              }
            }

            .relation-title {
              padding-left: 6px;
              display: flex;
              width: 100%;
              white-space: nowrap;

              i {
                display: inline-block;
                transform: translateY(-50%);
                margin: 0 3px;
                padding: 0;
                width: 10px;
                height: 22px;
                border-bottom: 2px dashed #101010;
              }

              .relation-entity-name {
                padding-right: 1px;
                max-width: 55%;
              }
            }

            // 编辑按钮
            &:last-child {
              text-align: right;
              a {
                color: #4095e5;
              }
            }
          }
        }

        // 关系tag
        .tag-belong {
          text-align: left;
          padding-top: 13px;

          .el-tag {
            height: 20px;
            padding: 0 15px;
            border: none;
            background-color: #64a6e1;
            color: #ffffff;
            border-radius: 10px;
            margin-right: 10px;
          }
        }

        // 描述信息
        .description {
          padding-top: 19px;
          text-align: left;
          color: #101010;
          line-height: 20px;
        }

        // 列表信息
        .detail-list {
          padding-top: 20px;
          text-align: left;
          line-height: 20px;
          color: #101010;

          .detail-name {
            width: 80px;
          }

          .detail-value {
            flex: 1;
          }
        }
      }

      .entity-detail-wrapper {
        .tag-belong {
          .el-tag {
            background-color: #1a73e8;
          }
        }

        img {
          width: 100%;
        }

        .description {
          color: #666666;
        }
      }
    }
  }
}
</style>
