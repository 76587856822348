<template>
  <!-- antv-g6 图谱 -->
  <div
    class="graph-wrapper"
    :class="{ 'graph-wrapper-all': state.ifShowAllScreen }"
    ref="graphWrapperRef"
  >
    <div id="graph-container"></div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  defineEmits,
  defineExpose,
  defineProps,
  inject,
  nextTick,
} from "vue";
import G6 from "@antv/g6";
import { useRouter, useRoute } from "vue-router";
import insertCss from "insert-css";
import { graphTypeColor } from "@/constant/common.js";
insertCss(`
  .g6-toolbar-ul {
    position: absolute;
    display: flex;
    bottom: 30px;
    right: 30px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    color: #545454;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 8px;
    box-shadow: rgb(174, 174, 174) 0px 0px 5px;
    width: 100px;
    cursor: pointer;
  }

  .g6-toolbar-ul li{
    padding: 0 5px;
  }
  
`);
let router = useRouter();
let route = useRoute();
const reload = inject("reload");
const emit = defineEmits(["initData", "selectNode", "selectEdge"]);
const props = defineProps({
  screenValue: { type: String },
});
const graphWrapperRef = ref(null);
const state = reactive({
  graph: null, //new G6实例
  canvasWidth: 0, // 画布宽度
  canvasHeight: 0, // 画布高度
  clickedNodeEvent: null,
  allEntityId: [], // 当前图谱内展示的所有实体id
  allRelationId: [], //当前图谱内展示的所有关系id
  ifShowAllScreen: false, //是否全屏展示图谱
});

/**初始化图谱 */
const initGraph = (rawData, newData) => {
  // console.log("raw-node-edge", rawData);
  // console.log("new-node-edge", newData);
  // 更新图谱
  if (state.graph) {
    let deletedData = getDifferData(rawData, newData); // 需要删除的结点与边数据
    let addedDate = getDifferData(newData, rawData); //需要增加的结点与边数据
    // console.log("需要删除的结点与边数据", deletedData);
    // console.log("需要增加的结点与边数据", addedDate);

    // 删除结点与边
    deletedData.subsetNode.forEach((v) => {
      const item = state.graph.findById(v.id);
      state.graph.remove(item);
    });
    addedDate.subsetEdge.forEach((e) => {
      const item = state.graph.findById(e.id);
      state.graph.remove(item);
    });
    // 追加结点与边
    addedDate.subsetNode.forEach((v) => {
      v = Object.assign(v, getRandomPosition(state.clickedNodeEvent));
      state.graph.addItem("node", v);
    });
    addedDate.subsetEdge.forEach((e) => {
      state.graph.addItem("edge", e);
    });
    state.graph.layout();
  }

  // 首次加载图谱
  else {
    const tooltip = new G6.Tooltip({
      offsetX: 0,
      offsetY: 15,
      fixToNode: [1, 0.5],
      // the types of items that allow the tooltip show up
      // 允许出现 tooltip 的 item 类型
      itemTypes: ["node", "edge"],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e) => {
        const outDiv = document.createElement("div");
        outDiv.style.width = "fit-content";
        outDiv.style.height = "fit-content";
        const model = e.item.getModel();
        if (e.item.getType() === "node") {
          outDiv.innerHTML = `${model.label}`;
        } else {
          outDiv.innerHTML = `${model.relationship}`;
        }
        return outDiv;
      },
    });
    /**清空状态 */
    const clearAllStatus = () => {
      state.graph.setAutoPaint(false);
      state.graph.getNodes().forEach(function (node) {
        state.graph.clearItemStates(node);
      });
      state.graph.getEdges().forEach(function (edge) {
        state.graph.clearItemStates(edge);
      });
      state.graph.paint();
      state.graph.setAutoPaint(true);
    };

    state.canvasWidth = graphWrapperRef.value.clientWidth;
    state.canvasHeight = graphWrapperRef.value.clientHeight;

    // 工具条
    const toolbar = new G6.ToolBar({
      // container: tc,
      className: "g6-toolbar-ul",
      // <li code='big'><i class="iconfont icon-fangda"></i></li>
      // <li code='small'><i class="iconfont icon-suoxiao"></i></li>
      getContent: () => {
        return `
      <ul>
        <li code='all'><i class="iconfont icon-quanping"></i></li>
        <li code='little'><i class="iconfont icon-filtercenterfocus"></i></li>
        <li code='redo'><i class="iconfont icon-zhongzhi"></i></li>
      </ul>
    `;
      },
      handleClick: (code) => {
        // 放大
        // 缩小
        //全屏
        if (code === "all") {
          state.ifShowAllScreen = true;
          nextTick(() => {
            initSize();
          });
        }
        // 退出全屏
        else if (code === "little") {
          state.ifShowAllScreen = false;
          nextTick(() => {
            initSize();
          });
        }
        // 重置
        else if (code === "redo") {
          reload();
        }
      },
    });

    state.graph = new G6.Graph({
      container: "graph-container", // 容器 id 或容器本身
      width: state.canvasWidth, // 图的宽度
      height: state.canvasHeight, // 图的高度
      groupByTypes: false,
      plugins: [tooltip, toolbar],
      layout: {
        type: "force", // 经典力导向图
        preventOverlap: true, // 避免节点重叠
        // nodeSize: 50, // 元素大小，用于计算避免节点重叠
        linkDistance: (d) => {
          // let base = 150;
          // let step = 40;
          // return step * parseInt(d.index / 40) + base;
          return 150;
        }, // 边长
        nodeSpacing: 20,
        animateCfg: {
          duration: 50,
        },
      },
      modes: {
        default: [
          "zoom-canvas",
          "drag-canvas",
          "drag-node",
          {
            type: "activate-relations",
            trigger: "click",
          },
          // {
          //   type: "click-select",
          //   multiple: false,
          //   selectEdge: true,
          // },
          {
            type: "collapse-expand-combo",
            trigger: "click",
          },
          "drag-combo",
        ],
      }, // 模式
      defaultNode: {
        type: "circle",
        // size: [50], //圆的直径
        style: {
          fill: "#93D2F3", //背景色
          stroke: "#4095E5",
          lineWidth: 1, //描边宽度
          fillOpacity: 0.3,
        }, //circle 默认样式
        labelCfg: {
          position: "top",
          style: {
            fill: "#000", //字体颜色
            fontSize: 12, //字体大小
          },
        }, //标签文本配置项
      },
      nodeStateStyles: {
        active: {
          fill: "#93D2F3",
          stroke: "#4095E5",
          lineWidth: 3,
          fillOpacity: 0.3,
          shadowColor: "Transparent",
        },
        inactive: {
          fill: "#93D2F3",
          stroke: "#93D2F3",
          lineWidth: 1,
          fillOpacity: 0.1,
        },
        selected: {
          shadowColor: "#4095E5",
          shadowBlur: 7,
        },
      }, // 节点状态样式
      defaultEdge: {
        style: {
          stroke: "#BBBBBB", //边的颜色
          lineAppendWidth: 2, //边的宽度

          // endArrow: {
          //   path: G6.Arrow.triangle(10, 15, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
          //   d: 0,
          // },
          // startArrow: {
          //   path: G6.Arrow.triangle(10, 15, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
          //   d: 0,
          // },
        },
      },
      edgeStateStyles: {
        active: {
          stroke: "#4095E5",
          lineWidth: 3,
        },
        inactive: {
          stroke: "#93D2F3",
          lineWidth: 1,
          strokeOpacity: 0.7,
        },
        selected: {
          shadowColor: "#4095E5",
          shadowBlur: 7,
        },
      }, // 边状态样式
      defaultCombo: {
        type: "circle",
        style: {
          fill: "#EFEFEF",
          stroke: "#bbb",
          lineDash: [4],
          lineWidth: 5,
        },
      },
      comboStateStyles: {
        active: {
          fill: "#fff",
          stroke: "#bbb",
          lineDash: [4],
          lineWidth: 5,
          shadowColor: "Transparent",
        },
        // inactive: {
        //   stroke: "#93D2F3",
        //   lineWidth: 1,
        //   strokeOpacity: 0.7,
        // },
        selected: {
          fill: "#fff",
          stroke: "#bbb",
          lineDash: [4],
          lineWidth: 2,
          shadowColor: "Transparent",
        },
      }, // 边状态样式
    });

    state.graph.node((node) => {
      const fillColor = graphTypeColor.find(
        (item) => item.type == node.type
      ).fill;
      const strokeColor = graphTypeColor.find(
        (item) => item.type == node.type
      ).stroke;
      return {
        style: {
          fill: fillColor,
          stroke: strokeColor,
        },
      };
    });

    state.graph.data(newData); // 读取 Step 2 中的数据源到图上
    state.graph.render(); // 渲染图

    // 为结点添加 是否展开 属性
    state.graph.getNodes().forEach(function (node) {
      // console.log(node);
      // 根节点默认展开
      if (node._cfg.model.id === route.query.entityId) {
        // console.log("root", node);
        node["ifClick"] = true;
      } else {
        node["ifClick"] = false;
      }
      // console.log(node);
    });
    // console.log(state.graph.getNodes());

    /**canvas */
    state.graph.on("click", (ev) => {
      if (!ev.target.cfg.type) {
        console.log("@@@空白处");
        clearAllStatus();
      }
    });

    /**node */
    state.graph.on("node:click", (evt) => {
      // const { item } = evt;
      // state.graph.focusItem(item, true, {
      //   easing: "easeCubic",
      //   duration: 500,
      // });
      // console.log(evt.item.ifClick);
      if (evt.item.ifClick) {
        // console.log("33333");
        return;
      } else {
        // 固定结点位置，向外拉长
        const model = evt.item.get("model");
        // 画布移动位置
        let newX = 0;
        let newY = 0;
        if (evt.x < 476) {
          newX = 150;
        } else {
          newX = -150;
        }
        if (evt.y < 250) {
          newY = 150;
        } else {
          newY = -150;
        }
        model.fx = evt.x < 476 ? evt.x - 150 : evt.x + 150;
        model.fy = evt.y > 250 ? evt.y + 150 : evt.y - 150;
        model.x = evt.x < 476 ? evt.x - 150 : evt.x + 150;
        model.y = evt.y > 250 ? evt.y + 150 : evt.y - 150;

        // 移动画布
        state.graph.translate(newX, newY);
        evt.item.ifClick = true;
      }
      // console.log(evt.item.ifClick);
    });

    state.graph.on("node:dblclick", (evt) => {
      const node = evt.item;
      const model = node.getModel();
      clearAllStatus();
      state.graph.setItemState(node, "selected", true);
      // 查询并重绘图谱
      // await handleSearchGraph(model);
      // 将node详情展示在右侧边栏
      emit("selectNode", model);
    });
    /**拖拽结点,固定位置 */
    state.graph.on("node:dragstart", function (e) {
      state.graph.layout();
      refreshDragedNodePosition(e);
    });
    state.graph.on("node:drag", function (e) {
      refreshDragedNodePosition(e);
    });
    // 拖拽结点后固定坐标
    const refreshDragedNodePosition = (e) => {
      const model = e.item.get("model");
      model.fx = e.x;
      model.fy = e.y;
      model.x = e.x;
      model.y = e.y;
    };

    /**edge */
    state.graph.on("edge:mouseenter", function (evt) {
      const edge = evt.item;
      const model = edge.getModel();
      state.graph.updateItem(edge, {
        label: model.relationship,
      });
    });
    state.graph.on("edge:mouseleave", function (evt) {
      const edge = evt.item;
      state.graph.setItemState(edge, "hover", false);
      // state.graph.updateItem(edge, {
      //   label: "",
      // });
    });
    state.graph.on("edge:dblclick", (evt) => {
      const edge = evt.item;
      const model = edge.getModel();
      clearAllStatus();
      state.graph.setItemState(edge, "selected", true);
      // 将node详情展示在右侧边栏
      emit("selectEdge", model);
    });

    /**activate-relations事件 */
    state.graph.on("afteractivaterelations", (e) => {
      // console.log("afteractivaterelations");
      // 当前操作的节点 item
      const model = e.item.getModel();
      // 当前操作是选中(`'activate'`)还是取消选中(`'deactivate'`)
      state.clickedNodeEvent = e;
      handleSearchGraph(model);
    });
  }
};

/**计算差集 */
const getDifferData = (dataLeft, dataRight) => {
  let setLeftNode = [];
  let setLeftEdge = [];
  let setRightNode = [];
  let setRightEdge = [];

  // 拿到id数据集合
  dataLeft.nodes.forEach((node) => {
    setLeftNode.push(node.id);
  });
  dataLeft.edges.forEach((edge) => {
    setLeftEdge.push(edge.id);
  });
  dataRight.nodes.forEach((node) => {
    setRightNode.push(node.id);
  });
  dataRight.edges.forEach((edge) => {
    setRightEdge.push(edge.id);
  });

  let subDate = {
    subsetNode: [],
    subsetEdge: [],
  };

  for (let item of setLeftNode) {
    if (setRightNode.indexOf(item) === -1) {
      subDate.subsetNode.push(item);
    }
  }
  for (let item of setLeftEdge) {
    if (setRightEdge.indexOf(item) === -1) {
      subDate.subsetEdge.push(item);
    }
  }

  for (let v = 0; v < subDate.subsetNode.length; v++) {
    for (let n = 0; n < dataLeft.nodes.length; n++) {
      if (dataLeft.nodes[n].id === subDate.subsetNode[v]) {
        subDate.subsetNode[v] = dataLeft.nodes[n];
      }
    }
  }
  for (let v = 0; v < subDate.subsetEdge.length; v++) {
    // console.log(subDate.subsetEdge[v]);
    for (let n = 0; n < dataLeft.edges.length; n++) {
      // console.log(dataLeft.edges[n]);
      if (dataLeft.edges[n].id === subDate.subsetEdge[v]) {
        subDate.subsetEdge[v] = dataLeft.edges[n];
      }
    }
  }

  return subDate;
};

//获取随机点坐标
const getRandomPosition = (e) => {
  if (e) {
    //e--当前节点对象   graph--G6.Graph对象
    //生成的坐标点
    let X = 0;
    let Y = 0;
    //获取半径
    let R = state.graph.cfg.layout.linkDistance;
    if (R == undefined) {
      R = 300;
    }

    let Cx = parseInt(e.item._cfg.model.x);
    let Cy = parseInt(e.item._cfg.model.y);
    // console.log(Cx, Cy);

    //随机横坐标
    let Rx = parseInt(Math.floor(Math.random() * R * 2) + 1 - R);
    //1- 一二象限 2-三四象限
    let qd = Math.floor(Math.random() * 2) + 1;

    if (qd == 1) {
      X = Cx + Rx;
      Y = Cy - parseInt(Math.sqrt(R * R - Rx * Rx));
    } else {
      X = Cx + Rx;
      Y = Cy + parseInt(Math.sqrt(R * R - Rx * Rx));
    }

    return {
      x: X,
      y: Y,
    };
  }
};
/** */
/**窗口大小改变时修改画布大小 */
const initSizeOnWindowResize = () => {
  setTimeout(() => {
    // 浏览器窗口发生变化时
    window.onresize = function () {
      initSize();
    };
  }, 0);
};
/**修改画布大小 */
const initSize = () => {
  // allScreen如果为true代表展示全屏
  // 获取div parentContent 的宽度和高度
  console.log(props.screenValue);
  if (graphWrapperRef.value) {
    // 如果是全屏状态
    if (state.ifShowAllScreen) {
      state.canvasWidth = graphWrapperRef.value.clientWidth;
      state.canvasHeight = graphWrapperRef.value.clientHeight;
    }
    // 如果是非全屏状态
    if (!state.ifShowAllScreen) {
      // 如果筛选栏是隐藏状态
      if (props.screenValue === "hide") {
        let h = document.body.clientHeight - 118;
        state.canvasWidth = graphWrapperRef.value.clientWidth;
        state.canvasHeight = h;
      }
      // 如果筛选栏是显示状态
      else {
        state.canvasWidth = graphWrapperRef.value.clientWidth;
        state.canvasHeight = graphWrapperRef.value.clientHeight;
      }
    }
  } else {
    return;
  }
  // 修改画布的大小
  console.log(state.canvasWidth, state.canvasHeight);
  state.graph.changeSize(state.canvasWidth, state.canvasHeight);
  // 将图移动到画布中心位置
  state.graph.fitCenter();
};
/**查询图谱数据 */
const handleSearchGraph = (model) => {
  emit("initData", model);
};
onMounted(async () => {
  initSizeOnWindowResize();
});
defineExpose({
  initGraph,
  initSize,
});
</script>

<style lang="scss" scoped>
// 图谱半屏样式
.graph-wrapper {
  width: 100%;
  height: 500px;
  // position: absolute;
  // left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
}
// 图谱全屏样式
.graph-wrapper-all {
  position: fixed !important;
  z-index: 99999 !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #fff !important;
}
.graph-container {
  height: 100%;
}
</style>
