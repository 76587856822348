<template>
  <!-- 图谱目录视图 -->
  <div
    class="entity-detail"
    v-show="state.isEntityReady && state.isRelationReady"
  >
    <!-- 标题 -->
    <div class="title">
      <h1>{{ state.name }}</h1>
      <span v-if="state.disambiguatio">{{
        "（" + state.disambiguation + "）"
      }}</span>
    </div>
    <!-- 实体tag -->
    <el-tag
      class="entity-type-tag"
      :disable-transitions="true"
      v-for="(typeItem, typeIndex) in state.typeList"
      :key="typeIndex"
    >
      {{ typeItem }}
    </el-tag>

    <!-- 描述信息 -->
    <div class="entity-description" v-if="state.description">
      <h3 class="entity-description-title">描述</h3>
      <div class="description-info">
        <p class="kn-text-align-justify kn-ellipsis-multiple">
          <span class="description-text">{{ state.description }}</span>
        </p>
      </div>
      <div class="spread-informatin" v-if="state.ifDescriptionCollapseVisible">
        <el-button size="small" @click="handleSpreadDescription">
          <i
            class="iconfont icon-doublearrow"
            :class="{ 'all-information': !state.ifDescriptionCollapse }"
          ></i>
          <span>{{ state.ifDescriptionCollapse ? "展开" : "收起" }}</span>
        </el-button>
      </div>
    </div>
    <!-- 属性 -->
    <div class="entity-attribute" v-if="state.attributesList">
      <h3 class="entity-attribute-title">属性</h3>
      <el-row class="attribute-content">
        <el-col
          :span="12"
          v-for="(
            attributesItem, attributesIndex
          ) in state.attributesList.slice(0, state.defaultAttributeNum)"
          :key="attributesIndex"
        >
          <div class="attribute-item">
            <span class="attribute-name">{{ attributesItem.name }}</span>
            <span class="attribute-value">{{ attributesItem.value }}</span>
          </div>
        </el-col>
      </el-row>
      <div class="spread-informatin" v-if="state.ifAttributionCollapseVisible">
        <el-button
          size="small"
          @click="handleSpreadAttribute(state.attributesList.length)"
        >
          <i
            class="iconfont icon-doublearrow"
            :class="{ 'all-information': !state.ifAttributionCollapse }"
          ></i>
          <span>{{ state.ifAttributionCollapse ? "展开" : "收起" }}</span>
        </el-button>
      </div>
    </div>
    <!-- 关系 -->
    <div class="entity-relation" v-if="state.relationEntitiesList.length !== 0">
      <h3 class="entity-relation-title">关系</h3>
      <div class="all-relation">
        <div
          class="relation-item"
          v-for="(
            relationEntitiesItem, relationEntitiesIndex
          ) in state.relationEntitiesList.slice(0, state.defaultRelationNum)"
          :key="relationEntitiesIndex"
        >
          <span class="relation-name">{{ relationEntitiesItem.name }}</span>
          <el-space wrap class="relation-value-wrapper">
            <!-- 选中样式 -->
            <!-- <el-tag
              :disable-transitions="true"
              :class="{ 'selected-relation': true }"
              >护理札记</el-tag
            > -->
            <el-tag
              v-for="(
                relationItem, relationIndex
              ) in relationEntitiesItem.relationList.slice(
                0,
                relationEntitiesItem.showNum
              )"
              :key="relationIndex"
              class="relation-value"
              :class="{ 'selected-relation': relationItem.selected }"
              @click="
                handleShowEntityDetailCard(
                  relationItem,
                  relationItem.targetId === state.entityId
                    ? relationItem.sourceId
                    : relationItem.targetId,
                  'entity'
                )
              "
              :disable-transitions="true"
              >{{
                relationItem.targetName === state.name
                  ? relationItem.sourceName
                  : relationItem.targetName
              }}</el-tag
            >
            <el-tag
              :disable-transitions="true"
              class="more"
              v-if="handleCollapseMoreItemBtnVisible(relationEntitiesItem)"
              @click="handleSpreadRelationEntity(relationEntitiesItem)"
              >等{{
                relationEntitiesItem.relationList.length -
                defaultRelationEntityNum
              }}项</el-tag
            >
          </el-space>
        </div>
      </div>
      <!-- 展开更多 -->
      <div class="spread-informatin" v-if="state.ifRelationCollapseVisible">
        <el-button
          size="small"
          @click="handleSpreadRelation(state.relationEntitiesList.length)"
        >
          <i
            class="iconfont icon-doublearrow"
            :class="{ 'all-information': !state.ifRelationCollapse }"
          ></i>
          <span>{{ state.ifRelationCollapse ? "展开" : "收起" }}</span>
        </el-button>
      </div>
    </div>
  </div>
  <!-- 骨架屏 -->
  <el-skeleton
    v-show="!state.isEntityReady || !state.isRelationReady"
    :animated="true"
  >
    <template #template>
      <!-- 标题 -->
      <el-skeleton-item class="title" variant="text" /><br />
      <!-- 实体类型tag -->
      <el-skeleton-item class="entity-type-tag" variant="text" /><br />
      <!-- 描述小标题 -->
      <el-skeleton-item class="entity-description-title" variant="text" /><br />
      <!-- 描述 -->
      <el-skeleton-item
        class="description-info"
        variant="text"
        v-for="index in 4"
        :key="index"
      /><br />
      <!-- 属性小标题 -->
      <el-skeleton-item class="entity-attribute-title" variant="text" /><br />
      <!-- 属性 -->
      <div class="attribute-content" v-for="index in 3" :key="index">
        <div class="attribute-item">
          <el-skeleton-item class="attribute-name" variant="text" />
          <el-skeleton-item class="attribute-value" variant="text" />
        </div>

        <div class="attribute-item">
          <el-skeleton-item class="attribute-name" variant="text" />
          <el-skeleton-item class="attribute-value" variant="text" />
        </div>
      </div>
      <!-- 关系标题 -->
      <el-skeleton-item class="entity-relation-title" variant="text" /><br />
      <!-- 关系 -->
      <div class="all-relation" v-for="index in 2" :key="index">
        <div class="relation-item">
          <el-skeleton-item class="relation-name" variant="text" />
          <div class="relation-value-wrapper">
            <el-skeleton-item
              class="relation-value"
              v-for="index in 5"
              :key="index"
              variant="text"
            />
          </div>
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<script setup>
import {
  ref,
  reactive,
  defineProps,
  watch,
  computed,
  onMounted,
  nextTick,
} from "vue";
import { httpPost } from "@/api/httpService";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  entityId: {
    type: String,
  },
});
const defaultAttributeNum = 6;
const defaultRelationNum = 5;
const defaultRelationEntityNum = 5;
const state = reactive({
  isEntityReady: false, //实体详情数据是否返回完毕
  isRelationReady: false, //实体关系数据是否返回完毕
  entityId: "", //实体id
  name: "", // 实体名称
  disambiguation: "", // 注解
  typeList: [], // 实体类型集合
  description: "", // 描述，简介
  attributesList: [], //属性列表
  defaultAttributeNum: defaultAttributeNum, // 默认展示的属性个数
  relationEntitiesList: [], // 关系聚合集合
  defaultRelationNum: defaultRelationNum, // 默认展示的关系个数
  ifDescriptionCollapseVisible: false,
  ifAttributionCollapseVisible: false,
  ifRelationCollapseVisible: false,
  ifDescriptionCollapse: false, //是否展开所有描述
  ifAttributionCollapse: false, // 是否展示所有属性
  ifRelationCollapse: false, // 是否展示所有关系
});
const ifDetailVisible = computed(() => store.state.ifDetailVisible); //是否显示详情卡片

/**描述的展开按钮显示与隐藏 */
const handleCollapseDescriptionBtnVisible = () => {
  if (
    document.querySelector(".description-text").offsetHeight <
    document.querySelector(".description-info").offsetHeight - 30
  ) {
    state.ifDescriptionCollapseVisible = false;
  } else {
    state.ifDescriptionCollapseVisible = true;
    state.ifDescriptionCollapse = true;
  }
};

/**展开描述 */
const handleSpreadDescription = () => {
  if (state.ifDescriptionCollapse) {
    let Height = document.querySelector(".description-text").offsetHeight + 30;
    document.querySelector(".description-info").style.maxHeight = Height + "px";
    document
      .querySelector(".kn-text-align-justify")
      .classList.remove("kn-ellipsis-multiple");
    state.ifDescriptionCollapse = false;
  } else {
    document.querySelector(".description-info").style.maxHeight = "110px";
    document
      .querySelector(".kn-text-align-justify")
      .classList.add("kn-ellipsis-multiple");
    state.ifDescriptionCollapse = true;
  }
};

/**属性的展开按钮显示与隐藏 */
const handleCollapseAttributionBtnVisible = () => {
  if (state.attributesList.length <= state.defaultAttributeNum) {
    state.ifAttributionCollapseVisible = false;
  } else {
    state.ifAttributionCollapseVisible = true;
    state.ifAttributionCollapse = true;
  }
};

/**展开属性 */
const handleSpreadAttribute = (attributesListLength) => {
  if (state.ifAttributionCollapse) {
    state.defaultAttributeNum = attributesListLength;
    state.ifAttributionCollapse = false;
  } else {
    state.defaultAttributeNum = defaultAttributeNum;
    state.ifAttributionCollapse = true;
  }
};

/**关系的展开按钮显示与隐藏 */
const handleCollapseRelationBtnVisible = () => {
  if (state.relationEntitiesList.length <= state.defaultRelationNum) {
    state.ifRelationCollapseVisible = false;
  } else {
    state.ifRelationCollapseVisible = true;
    state.ifRelationCollapse = true;
  }
};

/**展开关系 */
const handleSpreadRelation = (relationEntitiesListLength) => {
  if (state.ifRelationCollapse) {
    state.defaultRelationNum = relationEntitiesListLength;
    state.ifRelationCollapse = false;
  } else {
    state.defaultRelationNum = defaultRelationNum;
    state.ifRelationCollapse = true;
  }
};

/**展示更多关系实体按钮的显示与隐藏 */
const handleCollapseMoreItemBtnVisible = (relationEntitiesItem) => {
  if (
    relationEntitiesItem.relationList.length <= relationEntitiesItem.showNum
  ) {
    return false;
  } else {
    return true;
  }
};

/**展开关系实体 */
const handleSpreadRelationEntity = (relationEntitiesItem) => {
  relationEntitiesItem.showNum = relationEntitiesItem.relationList.length;
};

/**查询实体详情 */
const searchEntityDetail = async () => {
  state.isEntityReady = false;
  // console.log("view");
  // console.log(props.entityId);
  let url = "/graph/v1/searchEntityDetail";
  let params = {
    entityId: props.entityId,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    // console.log(res);
    state.entityId = res.data.entityId;
    state.name = res.data.name;
    state.disambiguation = res.data.disambiguation;
    state.typeList = res.data.typeList;
    state.description = res.data.description;
    state.attributesList = res.data.attributesList;
    // console.log(state);
    state.isEntityReady = true;
  }
};

/**查询关系列表 */
const searchEntityRelationAgg = async () => {
  state.isRelationReady = false;
  let url = "/graph/v1/searchEntityRelationAgg";
  let params = {
    entityId: props.entityId,
    keywords: null,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    state.relationEntitiesList = res.data;
    // console.log(state.relationEntitiesList);

    for (let r = 0; r < state.relationEntitiesList.length; r++) {
      state.relationEntitiesList[r]["showNum"] = defaultRelationEntityNum;
      for (
        let i = 0;
        i < state.relationEntitiesList[r].relationList.length;
        i++
      ) {
        // console.log(state.relationEntitiesList[r].relationList[i]);
        state.relationEntitiesList[r].relationList[i]["selected"] = false;
        // console.log(state.relationEntitiesList[r].relationList[i]);
      }
    }
    // console.log(state.relationEntitiesList);

    state.isRelationReady = true;
  }
};

// 选中关系实体，展示详情卡片
const handleShowEntityDetailCard = (item, entityId, type) => {
  for (let r = 0; r < state.relationEntitiesList.length; r++) {
    for (
      let i = 0;
      i < state.relationEntitiesList[r].relationList.length;
      i++
    ) {
      // console.log(state.relationEntitiesList[r].relationList[i]);
      state.relationEntitiesList[r].relationList[i]["selected"] = false;
      // console.log(state.relationEntitiesList[r].relationList[i]);
    }
  }
  item.selected = true;
  store.commit("handleIdForDetailCardChange", entityId);
  store.commit("handletTypeForDetailCardChange", type);
  store.commit("handleDetailShow");
  // console.log(entityId);
};

watch(
  () => state.isEntityReady && state.isRelationReady,
  () => {
    if (state.isEntityReady && state.isRelationReady) {
      nextTick(() => {
        // console.log(state.isEntityReady && state.isRelationReady);
        handleCollapseDescriptionBtnVisible();
        handleCollapseAttributionBtnVisible();
        handleCollapseRelationBtnVisible();
      });
    }
  },
  { immediate: false, deep: true }
);

onMounted(() => {
  searchEntityDetail();
  searchEntityRelationAgg();
});
</script>

<style lang="scss" scoped>
.entity-detail {
  padding: 0 50px;
  text-align: left;

  // 大标题
  .title {
    h1 {
      display: inline-block;
      font-size: 36px;
      font-weight: bold;
    }
    span {
      padding-left: 10px;
      font-size: 16px;
    }
  }

  // 实体tag
  .el-tag {
    height: 30px;
    margin: 15px 20px 0 0;
    padding: 0 20px;
    border: none;
    background-color: #1a73e8;
    color: #fff;
    border-radius: 15px;
    line-height: 30px;
    font-size: 18px;
  }

  .entity-description,
  .entity-attribute,
  .entity-relation {
    color: #666;

    // 二级标题
    h3 {
      font-weight: bold;
      font-size: 16px;
      color: #101010;
      margin: 50px 0 10px 0;
    }

    // 关闭展开按钮
    .spread-informatin {
      height: 35px;
      text-align: center;

      .el-button {
        margin: 0 auto;
        margin-top: 10px;
        line-height: 35px;
        cursor: pointer;
        color: #3894ff;
        border: none;
        --el-button-hover-bg-color: transparent;

        .iconfont {
          margin-right: 5px;
          vertical-align: middle;
          font-size: 12px;
        }

        .all-information {
          display: inline-block;
          transform: rotate(180deg) translateY(2px);
        }
      }
    }
  }

  // 描述信息
  .entity-description {
    .description-info {
      padding: 15px 14px;
      max-height: 110px;
      background-color: #f9f9f9;
      line-height: 20px;
    }
  }

  // 属性
  .entity-attribute {
    .attribute-content {
      padding: 20px 14px 0 14px;
      background-color: #f9f9f9;

      .el-col {
        line-height: 20px;
        padding-bottom: 20px;
        padding-right: 20px;

        .attribute-item {
          display: flex;

          .attribute-name {
            width: 90px;
            color: #101010;
            font-weight: bold;
            padding-right: 10px;
          }

          .attribute-value {
            flex: 1;
            color: #101010;
          }
        }
      }
    }
  }

  // 关系
  .entity-relation {
    .all-relation {
      display: flex;
      flex-direction: column;
      padding: 10px 14px;
      background-color: #f9f9f9;

      .relation-item {
        display: flex;
        margin-bottom: 15px;

        // 关系小标题
        .relation-name {
          width: 70px;
          padding-top: 18px;
          color: #101010;
          font-weight: bold;
        }

        // 关系内容
        .el-space {
          flex: 1;

          :deep(.el-space__item) {
            margin-right: 10px !important;
            margin-bottom: 5px;
          }

          .el-tag {
            height: 26px;
            border: none;
            border-radius: 13px;
            padding: 0 15px;
            cursor: pointer;
            line-height: 26px;
            font-size: 14px;
            background-color: #efefef;
            color: #101010;

            &:hover {
              color: #64a6e1;
            }
            &.selected-relation {
              color: #fff;
              background-color: #64a6e1;
              &:hover {
                color: #fff;
              }
            }
            &.more {
              color: #64a6e1;
              background-color: #fff;
              border: #64a6e1 1px solid;
            }
          }
        }
      }
    }
  }
}
// 骨架屏
.el-skeleton {
  width: 100%;
  padding: 0 50px;
  text-align: left;

  // 标题
  .title {
    width: 200px !important;
    height: 45px;
  }

  // 实体tag
  .entity-type-tag {
    width: 100px;
    height: 30px;
    margin-top: 10px;
    border-radius: 15px;
  }

  // 描述，属性，关系小标题
  .entity-description-title,
  .entity-attribute-title,
  .entity-relation-title {
    width: 40px;
    height: 20px;
    margin: 50px 0 10px 0;
  }

  // 描述
  .description-info {
    height: 20px;
    margin-bottom: 3px;
  }

  // 属性
  .attribute-content {
    display: flex;
    margin-bottom: 7px;
    .attribute-item {
      flex: 1;
      display: flex;
      .attribute-name {
        width: 70px;
        height: 20px;
        margin-right: 20px;
      }
      .attribute-value {
        width: 300px;
        height: 20px;
      }
    }
  }

  // 关系
  .all-relation {
    display: flex;
    margin-bottom: 7px;
    .relation-item {
      flex: 1;
      display: flex;
      .relation-name {
        width: 70px;
        height: 20px;
        margin-right: 20px;
      }
      .relation-value-wrapper {
        flex: 1;

        .relation-value {
          width: 100px;
          height: 20px;
          border-radius: 10px;
          margin-right: 35px;
        }
      }
    }
  }
}
</style>
