<template>
  <!-- 图谱搜索结果页 -->
  <el-container class="graph-wrapper">
    <!-- 左侧边栏 -->
    <graph-tabs ref="graphTabsRef"></graph-tabs>
    <!-- 主要内容 -->
    <el-main>
      <!-- 操作栏 -->
      <div class="graph-operation-wrapper">
        <div class="operation" v-if="state.viewValue === 'graph'">
          <div class="operation-name">筛选</div>
          <el-radio-group v-model="state.screenValue" @change="handleVisible">
            <el-radio-button label="show">显示</el-radio-button>
            <el-radio-button label="hide">隐藏</el-radio-button>
          </el-radio-group>
        </div>
        <div class="operation">
          <div class="operation-name">视图</div>
          <el-radio-group v-model="state.viewValue" @change="handleViewChange">
            <el-radio-button label="graph">
              <i class="iconfont icon-graph"></i>
            </el-radio-button>
            <el-radio-button label="catelogue">
              <i class="iconfont icon-list"></i>
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <el-scrollbar>
        <!-- 筛选栏 -->
        <graph-screen
          v-if="state.screenValue === 'show' && state.viewValue === 'graph'"
          ref="graphScreenRef"
          @change="onScreenChange"
        ></graph-screen>

        <!-- 图谱视图 -->
        <graph-antv-graph
          v-if="state.viewValue === 'graph'"
          ref="graphAntvGraphRef"
          @initData="onSearchGraph"
          @selectNode="onSelectNode"
          @selectEdge="onSelectEdge"
          :screenValue="state.screenValue"
        ></graph-antv-graph>

        <!-- 目录视图 -->
        <graph-entity-detail
          v-if="state.viewValue === 'catelogue'"
          ref="graphEntityDetailRef"
          :entityId="route.query.entityId"
        ></graph-entity-detail>
      </el-scrollbar>
    </el-main>
    <!-- 右侧详情 -->
    <graph-detail-card ref="graphDetailCardRef"></graph-detail-card>
  </el-container>
</template>

<script setup>
import { ref, reactive, onMounted, watch, nextTick } from "vue";
import { httpPost } from "@/api/httpService";
import { useRouter, useRoute } from "vue-router";
import GraphTabs from "@/components/graph/GraphTabs.vue";
import GraphScreen from "@/components/graph/GraphScreen.vue";
import GraphAntvGraph from "@/components/graph/GraphAntvGraph.vue";
import GraphEntityDetail from "@/components/graph/GraphEntityDetail.vue";
import GraphDetailCard from "@/components/graph/GraphDetailCard.vue";
import { useStore } from "vuex";
let router = useRouter();
let route = useRoute();
const store = useStore();

const graphTabsRef = ref(null); //侧边栏组件
const graphScreenRef = ref(null); //筛选栏组件
const graphAntvGraphRef = ref(null); //图谱组件
const graphEntityDetailRef = ref(null); //目录组件
const graphDetailCardRef = ref(null); //详情组件

const state = reactive({
  screenValue: "show",
  viewValue: "graph",
  entityList: [route.query.entityId], //需要查询的实体ID列表
  typeList: [], //需要查询的实体类型列表
  relationList: [], //需要查询的实体关系列表
  rawEntityDTOList: [], // 原始实体id列表
  rawRelationDTOList: [], // 原始关系id列表
  entityIdGoIn: "", //右侧卡片中要被进入详情的实体id
});

/**隐藏筛选项时，重新修改画布大小 */
const handleVisible = (label) => {
  if (label === "hide") {
    // console.log("hide");
    nextTick(() => {
      graphAntvGraphRef.value.initSize();
    });
  }
};

/**查询图谱 */
const searchGraph = async () => {
  let url = "/graph/v1/searchGraph";
  let params = {
    entityList: state.entityList,
    typeList: state.typeList,
    relationList: state.relationList,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    console.log("res", res.data);
    // 追加结点大小属性
    res.data.entityDTOList.forEach((v, i) => {
      v.size = i === 0 ? 40 : 20;
    });
    // 初始化图谱数据
    // console.log("旧实体id：", state.rawEntityDTOList);
    // console.log("旧关系id：", state.rawRelationDTOList);
    // console.log("新实体id：", res.data.entityDTOList);
    // console.log("新关系id：", res.data.relationDTOList);
    let rawDate = {
      entityDTOList: state.rawEntityDTOList,
      relationDTOList: state.rawRelationDTOList,
    };
    initGraphData(rawDate, res.data);
    // 获得当前图谱的全部实体类型和关系类型
    state.rawEntityDTOList = res.data.entityDTOList;
    state.rawRelationDTOList = res.data.relationDTOList;
  }
};
/**当单击节点时 */
const onSearchGraph = (model) => {
  state.entityList.push(model.id);
  searchGraph();
};
/**初始化图谱数据 */
const initGraphData = (rawData, newData) => {
  // rawData：旧图谱数据
  // newData：新图谱数据
  graphAntvGraphRef.value &&
    graphAntvGraphRef.value.initGraph(
      getInitData(rawData),
      getInitData(newData)
    );
};

/**将图谱数据变为node与edge数据 */
const getInitData = (data) => {
  let result = {
    nodes: [],
    edges: [],
  };
  result.nodes = data.entityDTOList.map((v) => {
    return {
      id: v.entityId,
      label: v.name,
      size: v.size,
      type: v.typeList[0],
    };
  });
  result.edges = data.relationDTOList.map((v) => {
    return {
      id: v.relationId,
      source: v.sourceId,
      target: v.targetId,
      label: v.type,
      relationship: v.type,
      type: v.curveOffset ? "arc" : "line",
      curveOffset: v.curveOffset,
      style: {
        startArrow: v.isTwoWay,
        endArrow: true,
      },
    };
  });
  // console.log("node-edge-data", data);
  return result;
};

// 展示目录视图或者图谱视图
const handleViewChange = (label) => {
  if (label === "graph") {
    searchGraph();
  }
};

/**筛选改变时 */
const onScreenChange = async (val) => {
  console.log("筛选改变时", val);
  // 获得筛选后的图谱数据
  state.typeList = val.typeList;
  state.relationList = val.relationList;
  searchGraph();
};

//#region 右侧详情
/**当双击选中节点时 */
const onSelectNode = (model) => {
  // graphDetailCardInfo.id = model.id;
  // graphDetailCardInfo.type = "entity";
  store.commit("handleDetailShow");
  store.commit("handleIdForDetailCardChange", model.id);
  store.commit("handletTypeForDetailCardChange", "entity");
};
/**当双击选中边时 */
const onSelectEdge = (model) => {
  // graphDetailCardInfo.id = model.id;
  // graphDetailCardInfo.type = "edge";
  store.commit("handleDetailShow");
  store.commit("handleIdForDetailCardChange", model.id);
  store.commit("handletTypeForDetailCardChange", "relation");
};
//#endregion 右侧详情
onMounted(() => {
  searchGraph();
});
</script>

<style lang="scss" scoped>
.graph-wrapper {
  .el-main {
    padding: 15px 0 0 0;
    background-color: #fdfdfd;
    .graph-operation-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 5px;
      .operation {
        display: flex;
        margin-right: 40px;
        .operation-name {
          margin-right: 15px;
          line-height: 34px;
        }
      }
    }

    .el-scrollbar {
      height: calc(100vh - 120px);
    }
  }
}
</style>
